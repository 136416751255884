<template>
  <div class="fill-height">
    <EbtMain></EbtMain>
  </div>
</template>

<script>

import EbtMain from "@/components/public_ebt/EbtMain.vue";

export default {
  name: "EbtView",
  components:{
    EbtMain
  }
}
</script>

<style scoped>

</style>