<template>
  <div >
    <l-map
        :zoom="zoom"
        :max-zoom="maxZoom"
        :center="centered.coordinates"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        style="z-index: 0; height: 700px; "
        :options="{scrollWheelZoom: false}"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />

      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"/>

      <l-control>
        <div class="text-center">
          <v-menu
              v-model="showLayers2"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="success"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="rounded-lg"
                  icon
                  large
                  outlined
                  elevation="10"
              >
                <v-icon>mdi-layers</v-icon>
              </v-btn>
            </template>

            <v-card width="500px" elevation="16" class="rounded-lg">
              <v-list>
                <v-list-item class="no-gutters" dense>
                  <v-list-item-content>
                    <v-list-item-title>{{$t('infrastruktur')}} & {{$t('sektor')}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                        icon
                        @click="showLayers2 = !showLayers2"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFInfrastructureType" :key="index"
                  >
                    <v-checkbox
                        v-if="lang==='id'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedInfraMarker(item)"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="lang==='en'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.descriptionEn.trim()"
                        class="caption"
                        @change="valueChangedInfraMarker(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFSectorType" :key="index"
                  >
                    <v-checkbox
                        v-if="lang==='id'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="lang==='en'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.descriptionEn.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-text v-if="false">
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFKomoditiType" :key="index"
                  >
                    <v-checkbox
                        v-if="lang==='id'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="lang==='en'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.descriptionEn.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>

            </v-card>
          </v-menu>
        </div>

      </l-control>

      <l-marker
          :key="computedCurrentMarker.id"
          :lat-lng="computedCurrentMarker.coordinates"
          ref="marker"
      >
        <l-icon
            :icon-size="computedCurrentMarker.iconSize"
            :icon-anchor="computedCurrentMarker.iconAnchor"
            :icon-url="computedCurrentMarker.iconUrl"
        />

      </l-marker>

      <l-geo-json
          v-for="(item, index) in computedItemsSpaMainGeojson" :key="index"
          :geojson="item.data"
          :options="options"
          :options-style="styleFunction"
      >
      </l-geo-json>

      <l-marker
          v-for="marker in computedInfraMarkers "
          :key="marker.id"
          :lat-lng="marker.coordinates"
      >
        <l-icon
            :icon-size="marker.iconSize"
            :icon-url="marker.iconUrl"
        />
        <l-popup>
          <v-flex class="d-flex" v-if="lang==='id'">
            {{ lookupFInfrastructure(marker.id).description }}
          </v-flex>
          <v-flex class="d-flex" v-if="lang==='en'">
            {{ lookupFInfrastructure(marker.id).descriptionEn }}
          </v-flex>
          <v-flex class="mt-1" v-if="lookupFInfrastructure(marker.id).district !==undefined">
            <div class="caption font-weight-light">
              {{ lookupFInfrastructure(marker.id).district }}
            </div>
          </v-flex>
        </l-popup>
      </l-marker>

    </l-map>

    <div class="d-flex">
      <v-autocomplete
          v-model="filterKabupaten"
          :items="fAreaFiltered"
          item-value="description"
          item-text="description"
          solo
          dense
          chips
          small-chips
          hide-details
          :label="$t('filterKabuptenKota')"
          class="ml-2 mt-n12 mr-2 rounded-lg"
          @change="fetchMapData"
      ></v-autocomplete>

      <v-spacer></v-spacer>
    </div>


  </div>
</template>

<script>
import { Icon } from "leaflet";
import { LMap, LTileLayer,
  LControl,
  LControlLayers,
  LMarker,
  LPopup,
  LIcon,
  LGeoJson } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import FileService from "@/services/apiservices/file-service";
import FInfrastructureService from "@/services/apiservices/f-infrastructure-service";
import jsondata from '../../assets/geojson/PotensiEnergiAngin.json'
import FPotensi from "@/models/f-potensi";

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "EbtMainMap",
  components: {
    LMap,
    LTileLayer,
    LControl,
    LControlLayers,
    LMarker,
    LIcon,
    LPopup,
    LGeoJson
  },
  props:{
    // itemModified: Object,
    itemsFile:[],
  },
  watch: {
    itemsFile:{
      handler: function (value, oldValue){
        // console.log(oldValue.length)
        // console.log(value)

        if (oldValue.length==0){
          // console.log(value)
          value.forEach(item => {
            if (item.selected){
              this.valueChangedLuasan(item)
            }
          })
        }
      }

    },
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },

  },
  data() {
    return {
      filterKabupaten: '',
      itemModified: new FPotensi(),
      enableTooltip: true,
      zoom: 9,
      maxZoom: 18,
      centered:{id: 1, coordinates: [-7.250445, 112.768845]},

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
              'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'Satelite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
      ],


      showLayers1: false,
      showLayers2: false,

      geojson: null,
      itemSpaMainGeojson: [],

      finfratructures:[],
      finfratructureSelected:[],

    };
  },
  computed:{
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    fAreaFiltered(){
      return this.$store.state.potensi.itemsFArea
    },

    computedCurrentMarker(){
      let marker = {id: 1, coordinates: [this.itemModified.lat, this.itemModified.lon]}

      const sectorType = this.lookupFSectorType(this.itemModified.fsectorTypeBean)
      const sectorTypeMarker1 = sectorType.markerImage1
      marker = {id: this.itemModified.id, coordinates: [this.itemModified.lat, this.itemModified.lon],
        iconUrl: this.lookupImageMarkerUrl(sectorTypeMarker1),
        iconSize: [45, 65],
      }

      /**
       * Melakukan Update secara Programming ke Center
       */
      if (this.itemModified.lat !==0 && this.itemModified.lon !==0) {
        this.$nextTick(() => {
          this.centered = marker
          this.$refs.marker.mapObject.openPopup()
        })
      }

      return marker
    },

    computedInfraMarkers(){
      let markers = []

      if (this.finfratructures !==undefined){
        let filter = this.filterKabupaten
        if (filter){
          filter = filter.toUpperCase().trim().replace("KOTA", "")
          filter = filter.replace("KABUPATEN", "").trim()
          console.log(filter)
        }
        // filter = ""
      // .filter(x => x.city.toUpperCase().trim().includes(this.filterKabupaten.toUpperCase().trim()))

        this.finfratructures.filter(x => x.city.toUpperCase().trim().includes(filter) ).forEach( item =>{

          console.log(item.city)

          if (item.lat !==0 && item.lon !==0){

            const infrastructureType = this.lookupFInfrastructureType(item.finfrastructureTypeBean)
            const infrastructureTypeMarker1 = infrastructureType.markerImage1

            let marker = {id: item.id, coordinates: [item.lat, item.lon],
              iconUrl: this.lookupImageMarkerUrl(infrastructureTypeMarker1),
              iconSize: [23, 35],
            }

            markers.push(marker)
          }
        })
      }

      return markers
    },

    computedItemsSpaMain(){
      return this.itemsFile.filter(item => item.potensiType===4 && item.fileType==="SPA")
    },


    computedItemsSpaExt(){
      return this.itemsFile.filter(item => item.potensiType===5 && item.fileType==="SPA")
    },

    computedItemsFSectorType(){
      return this.$store.state.potensi.itemsFSectorType
    },
    computedItemsFKomoditiType(){
      return this.$store.state.potensi.itemsFKomoditiType
    },

    computedItemsFInfrastructureType(){
      return this.$store.state.potensi.itemsFInfrastructureType
    },
    computedItemsSpaMainGeojson(){
      return this.itemSpaMainGeojson
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },
    styleFunction() {

      let lineColor = "orange";
      let fillColor = "orange";
      let opacity = 0.5;
      let filterKabKota = this.filterKabupaten

      return value => {
        // console.log(value.fillColor)

        // console.log(JSON.stringify(value.properties))

        lineColor = 'red'
        fillColor =  'red'
        opacity = 1

        // try{
        // if (value.properties.pola_ruang != undefined) {
        //   lineColor = this.lookupGeoJsonColor(value.properties.pola_ruang)
        //   fillColor = this.lookupGeoJsonColor(value.properties.pola_ruang)
        // }else if (value.properties.LAYER !== undefined){
        //   lineColor = this.lookupGeoJsonColor(value.properties.LAYER)
        //   fillColor = this.lookupGeoJsonColor(value.properties.LAYER)
        // }else if (value.properties.NAMOBJ !== undefined){
        //   lineColor = this.lookupGeoJsonColor(value.properties.NAMOBJ)
        //   fillColor = this.lookupGeoJsonColor(value.properties.NAMOBJ)
        // }else if (value.properties.KETERANGAN !== undefined){
        //   lineColor = this.lookupGeoJsonColor(value.properties.KETERANGAN)
        //   fillColor = this.lookupGeoJsonColor(value.properties.KETERANGAN)
        // }else if (value.properties.Keterangan !== undefined){
        //   lineColor = this.lookupGeoJsonColor(value.properties.Keterangan)
        //   fillColor = this.lookupGeoJsonColor(value.properties.Keterangan)
        // }


        if (value.properties.KABUPATEN !== undefined) {

          // console.log(filterKabKota.toUpperCase().trim())

          try{
            filterKabKota = filterKabKota.replace("Kota", "KOTAMADYA").toUpperCase().trim()
          }catch (e) {
            e.toString()
          }
          // filterKabKota = 'MALANG'
          // console.log(filterKabKota)


          if (value.properties.KABUPATEN.toUpperCase().trim().includes(filterKabKota)){
            lineColor = 'blue'
            fillColor = 'blue'
            opacity = 0.7
          }else {
            lineColor = 'orange'
            fillColor = 'orange'
            opacity = 0.2
          }
        }
          // }catch (e) {
        //   console.log(e)
        // }

        return {
          weight: 1,
          color: lineColor,
          opacity: opacity,
          fillColor: fillColor,
          fillOpacity: 0.4
        };
      };

    },

    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip(
            "<div style='font-weight: bold;'>" +
            "ENERGI ANGIN</div>" +
            "<div>" +
            feature.properties.WADMKK +
            "</div>" +
            "</div>Luas: " +
            feature.properties.Shape_Area +
            "<div>" +
            "</div>Sumber: " +
            feature.properties.Sumber +
            "" +
            "</div>",
            { permanent: false, sticky: true }
        );
      };

    }

  },
  methods: {

    zoomUpdated (zoom) {
      this.zoom = zoom;
      // console.log(this.markers)
    },
    centerUpdated (center) {
      this.center = center;
    },
    changeSelection(value){
      value.toString()

      const tipe = this.lookupFInfrastructureType(this.itemModified.finfrastructureTypeBean)
      const theIconUrl = this.lookupImageMarkerUrl(tipe.markerImage1)
      const newMarker =
          {
            id: 1,
            iconUrl: theIconUrl,
            coordinates: [value.lat, value.lon],
            iconSize: [45, 65],
            // iconAnchor: [16, 37]
          }

      this.$nextTick(() => {
        this.itemModified = Object.assign({}, value)
        this.currentMarker = newMarker
        this.zoom = 10
        this.$refs.marker.mapObject.openPopup()

      })

    },

    lookupFSectorType (fsectorTypeBean) {
      const str = this.computedItemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFInfrastructure (finfrastructureBean) {
      const str = this.finfratructures.filter(x => x.id===finfrastructureBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFInfrastructureType (finfrastructureTypeBean) {
      const str = this.computedItemsFInfrastructureType.filter(x => x.id===finfrastructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },
    lookupImageMarkerUrl(markerImage){
      if (markerImage===undefined || markerImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_ori(markerImage)
      }
    },

    lookupGeoJsonColor(value){
      let theColor = 'blue'

      if (value.toUpperCase().includes('PERMUKIMAN')){
        theColor = '#c9791c'
       }else {
        theColor = 'white'
      }
      return theColor
    },

    async valueChangedLuasan(value){

      // console.log(JSON.stringify(value))
      // const response = await fetch('https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson');
      // this.geojson = await response.json();

      const response = await fetch(FileService.file_url(value.fileName));

      // console.log(response)

      if (value.selected ===true){
        const geojsonResponse = await response.json();
        this.itemSpaMainGeojson.push( {id: value.id, data: geojsonResponse, lineColor: value.remark1, fillColor: value.remark2} )

      }else {
        // this.geojson = {}

        const index = this.itemSpaMainGeojson.findIndex(item => item.id === value.id)
        this.itemSpaMainGeojson.splice(index, 1)
      }
    },

    valueChangedInfraMarker(value){
      // console.log(JSON.stringify(value))

      if (value.selected ===true){
        this.finfratructureSelected.push(value.id)
      }else {
        const index = this.finfratructureSelected.findIndex(itemId => itemId === value.id)
        this.finfratructureSelected.splice(index, 1)
      }

      if (this.finfratructureSelected.length === 0) {
        this.finfratructures = []
        return
      }

      // console.log(this.itemModified.city)

      FInfrastructureService.getAllFInfrastructureByCityAndInfrastructurePublic(this.itemModified.city, this.finfratructureSelected).then(
          response =>{
            // console.log(JSON.stringify(response))
            this.finfratructures = response.data

          }
      )

    },

    fetchMapData(){
      this.itemSpaMainGeojson = []

      // let newFeatures = []
      // jsondata.features.filter( x => x.properties.KABUPATEN.toUpperCase().includes("MOJOKERTO")).forEach( x => {
      //   newFeatures.push(x)
      // })
      // const newJson = {
      //   type: "FeatureCollection",
      //   crs: jsondata.crs,
      //   features: newFeatures
      // }
      // const geojsonResponse = newJson

      const geojsonResponse = jsondata
      this.itemSpaMainGeojson.splice(1,1, {id: 1, data: geojsonResponse, lineColor: 'red', fillColor: 'orange'} )

    },

  },

  mounted() {
      this.fetchMapData()
  },


};
</script>

<style scoped>

</style>
